import React, { useState, useEffect, useCallback } from 'react';
import {
  App,
  AccordionContent,
  BlockFooter,
  Block,
  Button,
  Card,
  CardContent,
  Chip,
  View,
  Page,
  Preloader,
  Progressbar,
  Navbar,
  LoginScreen,
  LoginScreenTitle,
  List,
  ListInput,
  ListItem,
  Link,
  NavTitle,
  f7
} from 'framework7-react';
import axios from 'axios';

const GA_CLIENT_ID = '1092393785652-eluon044pa7rn4mdhus9jct1j0t9cua9.apps.googleusercontent.com';
const BASE_API_URL = 'https://ee137.uk';




function MyApp() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [permissionsNeeded, setPermissionsNeeded] = useState(false);
  const [listEditorValue, setListEditorValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [transitionProgress, setTransitionProgress] = useState(0);
  const [hasFirstTransition, setHasFirstTransition] = useState(false);

  const [tags, setTags] = useState([]);
  const [details, setDetails] = useState({});
  const [similarRequests, setSimilarRequests] = useState([]);
  const [clarificationQuestions, setClarificationQuestions] = useState([]);
  const [possibleSolution, setPossibleSolution] = useState([]);
  const [progress, setProgress] = useState(0);



  const fetchUserData = async () => {
        const token = localStorage.getItem('jwt_token');
        if (!token) {
            console.log("No token found");
            return;
        }

        try {
            const response = await axios.get(`${BASE_API_URL}/users/me`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data; // This contains the user data returned by the backend
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            return null;
        }
    }

    const getRandomColor = () => {
      const colors = ['red', 'green', 'blue', 'orange', 'pink', 'yellow', 'teal', 'purple', 'deep-orange', 'gray'];
      return colors[Math.floor(Math.random() * colors.length)];
    };

  const initializeGoogleSignIn = useCallback(() => {
    const existingElement = document.getElementById('g_id_onload');
    if (existingElement) {
      existingElement.remove();
    }

    const script = document.createElement('script');
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;

    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: GA_CLIENT_ID,
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById('google-signin-button'),
        { theme: "outline", size: "large" }
      );
    };

    document.body.appendChild(script);
  }, []);

      useEffect(() => {
        let intervalId;
        if (isLoading) {
          // Reset progress when loading starts
          setProgress(0);
          intervalId = setInterval(() => {
            setProgress((prevProgress) => {
              const randomIncrement = Math.floor(Math.random() * 10 + 5);
              return Math.min(prevProgress + randomIncrement, 100);
            });
          }, 3500); // Update progress every 2 seconds
        } else {
          // Ensure progress is set to 100 when not loading
          setProgress(100);
        }

        // Clean-up function to clear interval when component unmounts or isLoading changes
        return () => {
          clearInterval(intervalId);
        };
      }, [isLoading]);


  useEffect(() => {
    if (!isAuthenticated) {
      initializeGoogleSignIn();
    }
  }, [isAuthenticated, initializeGoogleSignIn]);

  useEffect(() => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      setIsAuthenticated(true);
    }

    window.handleCredentialResponse = handleCredentialResponse;

    const script = document.createElement('script');
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
        const userData = await fetchUserData();
        if (!userData) {
            console.log('Token validation failed or no user data available');
            localStorage.removeItem('jwt_token');
            setIsAuthenticated(false);
        }
    };

    verifyToken();
  }, []);

  const handleCredentialResponse = async (response) => {
    const googleToken = response.credential;
    setIsLoading(true);
    try {
      const res = await axios.post(BASE_API_URL+'/token', {
        password: googleToken
      });
      localStorage.setItem('jwt_token', res.data.access_token);
      setIsAuthenticated(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Authentication failed:', error);
      setPermissionsNeeded(true);
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  };


  const fetchAdditionalData = async (text) => {
      const token = localStorage.getItem('jwt_token');
      try {
        setTags([]);
        //setSummary("");
        setDetails({});
        setSimilarRequests([]);
        setClarificationQuestions([]);
        setPossibleSolution([]);

        const response = await axios.post(`${BASE_API_URL}/process_request`, { text }, { headers: { Authorization: `Bearer ${token}` } });

        const data = response.data;

        setTags(data.tags);
        //setSummary(data.summary);
        setDetails(data.details);
        //setSimilarRequests(data.similarRequests);
        setClarificationQuestions(data.clarificationQuestions);
        setPossibleSolution(data.possibleSolution);



      } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('jwt_token');
            setIsAuthenticated(false);
        }
        console.error('Failed to fetch additional data:', error);
      }
    };


  const helpHandler = async () => {
      if (!listEditorValue.trim()) return;

      document.querySelector('.page-content').scrollTo(0, 0);
      console.log('Help requested:', listEditorValue);
      setIsLoading(true);
      f7.preloader.show();

      try {
        const token = localStorage.getItem('jwt_token');
        const response = await axios.post(`${BASE_API_URL}/validate_request`, { text: listEditorValue }, { headers: { Authorization: `Bearer ${token}` } });

        if (response.data.result === 0) {
          f7.dialog.alert('Your request must be a valid support request.', 'Validation Failed');
          setIsLoading(false);
          f7.preloader.hide();
        } else if (response.data.result === 1) {
          await fetchAdditionalData(listEditorValue);
          f7.preloader.hide();
          setIsLoading(false);
          startTransitionAnimation();
          const token = localStorage.getItem('jwt_token');
          // Second API call to the new /search_requests endpoint
          const text = listEditorValue;
            const searchResponse = await axios.post(
              `${BASE_API_URL}/search_requests`,
              { text },
              { headers: { Authorization: `Bearer ${token}` } }
            );

            // Update state with data from /search_requests
            console.log("searchResponse.data")
            console.log(searchResponse.data)
            setSimilarRequests(searchResponse.data.similar);
        }
      } catch (error) {
        // if error code is 401
        if (error.response.status === 401) {
            localStorage.removeItem('jwt_token');
            setIsAuthenticated(false);
        }
        console.error('Validation failed:', error);
        setIsLoading(false);
        f7.preloader.hide();

      }
    };


  const startTransitionAnimation = () => {
    let startTime = null;
    const animateTransition = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / 2000, 1);

      setTransitionProgress(progress);

      if (progress < 1) {
        requestAnimationFrame(animateTransition);
      } else {
        setTransitionProgress(0);
        setHasFirstTransition(true);
      }
    };

    requestAnimationFrame(animateTransition);
  };

  return (
    <App name="Cambridge UIS Support" theme="ios">
      {!isAuthenticated ? (
        <LoginScreen id="my-login-screen" opened={!isAuthenticated}>
          <View>
            <Page loginScreen>
              <LoginScreenTitle>Login with Google</LoginScreenTitle>
              <div align="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <div
                  id="google-signin-button"
                  style={{
                    width: '100%',
                    maxWidth: '250px',
                    height: '50px'
                  }}
                ></div>
              </div>
              <BlockFooter>
                Click the button above to sign in using your Google account (@cam.ac.uk)
                {permissionsNeeded && (
                    <>
                        <br/>
                        <span style={{ color: 'red' }}>
                            Permission is required to access the application. To request access, please contact the&nbsp;<a
                                href="mailto:ee345@cam.ac.uk?subject=Request for Access to Cambridge.Support&body=Hi, I would like to request access to the Cambridge.Support application. Please let me know if any additional steps are required. Thank you!"
                                class="external"
                            >administrator</a>.
                        </span>
                    </>
                )}
              </BlockFooter>
            </Page>
          </View>
        </LoginScreen>
      ) : (
        <View main className="safe-areas" url="/">
          <Page>
            <Navbar>
              <NavTitle>Cambridge UIS Support</NavTitle>
            </Navbar>
            {isLoading && (
                <Progressbar progress={progress} id="demo-inline-progressbar" />
            )}
            <Block>
              <div
                className={`grid ${hasFirstTransition ? 'medium-grid-cols-2 gap-4' : 'grid-cols-1'}`}
                style={{ transition: 'all 0.05s ease' }}
              >
                <div
                  style={{
                    width: `${!hasFirstTransition ? (100 - (transitionProgress * 50)) : '100'}%`,
                    transition: `${!hasFirstTransition ? 'width 0.05s ease' : ''}`,
                  }}
                >
                  <List strongIos outlineIos style={{ marginTop: '0px', marginBottom: '0px', paddingRight: '16px' }}>
                    <ListInput
                      style={{
                        minHeight: '400px',
                        maxHeight: '400px',
                        width: '100%',
                        transition: 'all 5s ease',
                        overflowY: 'auto'
                      }}
                      label="Help desk query"
                      type="textarea"
                      placeholder="Enter your query here..."
                      resizable
                      value={listEditorValue}
                      onInput={(e) => setListEditorValue(e.target.value)}
                      disabled={isLoading}
                    />
                  </List>
                  <List style={{ marginTop: '0px' }}>
                    <ListItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        fill
                        preloader
                        onClick={helpHandler}
                        loading={isLoading}
                      >
                        Submit Query
                      </Button>
                    </ListItem>
                  </List>
                </div>

                {hasFirstTransition && (
                  <div
                    style={{
                      opacity: 1,
                      transition: 'opacity 5s ease'
                    }}
                  >
                    <div className="grid medium-grid-cols-2 gap-4">
                      <div className="bg-gray-100 p-4 rounded">
                        <List dividersIos strong inset style={{ marginTop: '0px', marginBottom: '16px'}} accordionList>
                            <ListItem accordionItemOpened accordionItem title="Possible solution">
                              <AccordionContent>
                                <List>
                                {possibleSolution.length ? (
                                  possibleSolution.map((step, index) => (
                                    <ListItem key={index}>
                                      {step}
                                    </ListItem>
                                  ))
                                ) : (
                                        <Block style={{ marginBottom: '16px' }}>
                                            <Preloader />
                                        </Block>
                                )}
                                </List>
                              </AccordionContent>
                            </ListItem>
                        </List>
                        <List dividersIos strong inset style={{ marginTop: '0px', marginBottom: '16px'}} accordionList>
                            <ListItem accordionItem title="Clarification questions">
                              <AccordionContent>
                                <List>
                                {clarificationQuestions.length ? (
                                  clarificationQuestions.map((question, index) => (
                                    <ListItem key={index}>
                                      {question}
                                    </ListItem>
                                  ))
                                ) : (
                                        <Block style={{ marginBottom: '16px' }}>
                                            <Preloader />
                                        </Block>
                                )}
                                </List>
                              </AccordionContent>
                            </ListItem>
                        </List>
                      </div>
                      <div className="bg-gray-100 p-4 rounded">
                        <List dividersIos strong strongIos inset style={{ marginTop: '0px', marginBottom: '16px'}} accordionList>
                            <ListItem accordionItemOpened accordionItem title="Category, Sub, Service, Priority">
                              <AccordionContent>
                                <Card className="data-table data-table-collapsible data-table-init">
                                  <CardContent padding={false}>
                                    {details.category ? (
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td className="label-cell">Category</td>
                                            <td className="numeric-cell">{details.category}</td>
                                          </tr>
                                          <tr>
                                            <td className="label-cell">Subcategory</td>
                                            <td className="numeric-cell">{details.sub_category}</td>
                                          </tr>
                                          <tr>
                                            <td className="label-cell">Service</td>
                                            <td className="numeric-cell">{details.Service}</td>
                                          </tr>
                                          <tr>
                                            <td className="label-cell">Priority</td>
                                            <td className="numeric-cell">{details.Priority}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    ) : (
                                        <Block style={{ marginBottom: '16px' }}>
                                            <Preloader />
                                        </Block>
                                    )}
                                  </CardContent>
                                </Card>
                              </AccordionContent>
                            </ListItem>
                        </List>
                        <List dividersIos strong strongIos inset style={{ marginTop: '0px', marginBottom: '16px'}} accordionList>
                            <ListItem accordionItemOpened accordionItem title="Tags">
                              <AccordionContent>
                                <Block style={{ marginBottom: '16px' }}>
                                    {tags.length ? (
                                      tags.map((tag, index) => (
                                        <Chip key={index} text={tag}  color={getRandomColor()}/>
                                      ))
                                    ) : (
                                        <Block style={{ marginBottom: '16px' }}>
                                            <Preloader />
                                        </Block>
                                    )}
                                </Block>
                              </AccordionContent>
                            </ListItem>
                        </List>
                        <List dividersIos strong strongIos inset style={{ marginTop: '0px', marginBottom: '16px'}} accordionList>
                            <ListItem accordionItem title="Similar HEATs">
                              <AccordionContent>
                                {similarRequests.length ? (
                                  similarRequests.map((request, index) => (
                                    <Block key={index} strong>
                                      <Link target="_blank" href={"https://uniofcam.saasiteu.com/Default.aspx?Scope=ObjectWorkspace&CommandId=Search&ObjectType=Incident%23&CommandData=IncidentNumber,%3D,0,"+request} external>
                                        {request}
                                      </Link>
                                    </Block>
                                  ))
                                ) : (
                                    <Block style={{ marginBottom: '16px' }}>
                                        <Preloader />
                                    </Block>
                                )}
                              </AccordionContent>
                            </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Block>
          </Page>
        </View>
      )}
    </App>
  );
}

export default MyApp;
